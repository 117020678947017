<template>


<header class="bg-header  flex items-center justify-center h-screen rounded-lg">
  <div class="bg-white font-serif mx-4 p-4 text-center md:p-8">
    <p class="italic text-sm">
      May 15, 2022
    </p>
    <h1 class="text-5xl text-black uppercase">
    Wine Festival
    </h1>
      <a href="#">

                    <button class="
              mx-auto
              lg:mx-0
              hover:underline
              bg-indigo-600
              text-white
              font-bold
              rounded-full
              my-6
              py-4
              px-8
              shadow-lg
              focus:outline-none
              focus:shadow-outline
              transform
              transition
              hover:scale-105
              duration-300
              ease-in-out
              w-25
              text-center
            ">
                    Register
                    </button>
        </a>
  </div>
</header>

    <div class="bg-white lg:px-20 md:px-6 px-4 py-12">
        <div class="flex flex-col items-center justify-center">
            <h1 class="lg:text-5xl text-3xl font-bold text-center text-red-800 ">Our Clients Say!</h1>
             

            <div class="text-base leading-6 mt-4 text-center text-yellow-600 2xl:w-2/5 sm:w-96">
            Either give me more wine or leave me alone -- Rumi </div>
        </div>
        <div class="w-full lg:flex items-center gap-6 mt-10">
            <div class="lg:w-1/2">
                <div class="bg-white border rounded-md border-gray-200 relative sm:p-10 p-6">
                    <div>
                        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M24.5767 14.331H18.6157C18.7176 9.55831 19.9498 9.01654 21.4974 8.86191L22.0942 8.78063V3.53254L21.4063 3.57304C19.3854 3.69795 17.1512 4.09818 15.6605 6.11977C14.3539 7.89195 13.7778 10.7872 13.7778 15.2317V23.4674H24.5767V14.331Z" fill="#4338CA" />
                                <path d="M10.7988 23.4674V14.331H4.91744C5.01934 9.55831 6.21168 9.01654 7.75927 8.86191L8.31634 8.78063V3.53254L7.66816 3.57304C5.64729 3.69795 3.39306 4.09818 1.90245 6.11977C0.595916 7.89195 -5.72205e-06 10.7872 -5.72205e-06 15.2317V23.4674H10.7988Z" fill="#4338CA" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="24.5767" height="27" fill="white" transform="translate(24.5767 27) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <p class="text-base leading-6 text-gray-600 mt-4">Age is just a number. It's irrelevant unless, of course, you happen to be a bottle of wine. –Joan Collins</p>
                    <div class="absolute bottom-0 -mb-4 ml-10">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" width="20" height="21" fill="white" />
                            <path d="M21.5 6L11.5 21L1 6" stroke="#E5E7EB" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="flex items-center mt-7">
                    <div class="w-12 h-12 border border-indigo-700 rounded-full flex items-center justify-center">
                        <img src="https://i.ibb.co/R6WQhYj/Mask-Group.png" class="w-10 h-10 rounded-full" alt="profile" />
                    </div>
                    <div class="flex flex-col items-start ml-4">
                        <p class="text-base font-semibold leading-4 text-yellow-500">Alisha Cooper</p>
                        <p class="text-base leading-4 mt-2 text-center text-black ">Life Coach</p>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/2 lg:mt-0 mt-16">
                <div class="bg-white border rounded-md border-gray-200 relative sm:p-10 p-6">
                    <div>
                        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M24.5767 14.331H18.6157C18.7176 9.55831 19.9498 9.01654 21.4974 8.86191L22.0942 8.78063V3.53254L21.4063 3.57304C19.3854 3.69795 17.1512 4.09818 15.6605 6.11977C14.3539 7.89195 13.7778 10.7872 13.7778 15.2317V23.4674H24.5767V14.331Z" fill="#4338CA" />
                                <path d="M10.7988 23.4674V14.331H4.91744C5.01934 9.55831 6.21168 9.01654 7.75927 8.86191L8.31634 8.78063V3.53254L7.66816 3.57304C5.64729 3.69795 3.39306 4.09818 1.90245 6.11977C0.595916 7.89195 -5.72205e-06 10.7872 -5.72205e-06 15.2317V23.4674H10.7988Z" fill="#4338CA" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="24.5767" height="27" fill="white" transform="translate(24.5767 27) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p class="text-base leading-6 text-gray-600 mt-4">Typically a standout even amongst the best of Châteauneuf-du-Pape, this monumental bottling is sourced from 75- to 90-year-old Grenache planted in a diversity of pebbly, sandy and clay soils. </p>
                    <div class="absolute bottom-0 -mb-4 ml-10">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" width="20" height="21" fill="white" />
                            <path d="M21.5 6L11.5 21L1 6" stroke="#E5E7EB" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="flex items-center mt-7">
                    <div class="w-12 h-12 border border-indigo-700 rounded-full flex items-center justify-center">
                        <img src="https://i.ibb.co/C6bwf12/Mask-Group.png" class="w-10 h-10 rounded-full" alt="profile" />
                    </div>
                    <div class="flex flex-col items-start ml-4">
                        <p class="text-base font-semibold leading-4 text-yellow-500">Henry Jack</p>
                        <p class="text-base leading-4 mt-2 text-center text-black">Business - Engineering</p>
                    </div>
                </div>
            </div>
             <div class="lg:w-1/2">
                <div class="bg-white border rounded-md border-gray-200 relative sm:p-10 p-6">
                    <div>
                        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M24.5767 14.331H18.6157C18.7176 9.55831 19.9498 9.01654 21.4974 8.86191L22.0942 8.78063V3.53254L21.4063 3.57304C19.3854 3.69795 17.1512 4.09818 15.6605 6.11977C14.3539 7.89195 13.7778 10.7872 13.7778 15.2317V23.4674H24.5767V14.331Z" fill="#4338CA" />
                                <path d="M10.7988 23.4674V14.331H4.91744C5.01934 9.55831 6.21168 9.01654 7.75927 8.86191L8.31634 8.78063V3.53254L7.66816 3.57304C5.64729 3.69795 3.39306 4.09818 1.90245 6.11977C0.595916 7.89195 -5.72205e-06 10.7872 -5.72205e-06 15.2317V23.4674H10.7988Z" fill="#4338CA" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="24.5767" height="27" fill="white" transform="translate(24.5767 27) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <p class="text-base leading-6 text-gray-600 mt-4">Age is just a number. It's irrelevant unless, of course, you happen to be a bottle of wine. –Joan Collins</p>
                    <div class="absolute bottom-0 -mb-4 ml-10">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" width="20" height="21" fill="white" />
                            <path d="M21.5 6L11.5 21L1 6" stroke="#E5E7EB" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="flex items-center mt-7">
                    <div class="w-12 h-12 border border-indigo-700 rounded-full flex items-center justify-center">
                        <img src="https://i.ibb.co/R6WQhYj/Mask-Group.png" class="w-10 h-10 rounded-full" alt="profile" />
                    </div>
                    <div class="flex flex-col items-start ml-4">
                        <p class="text-base font-semibold leading-4 text-yellow-500">Alisha Cooper</p>
                        <p class="text-base leading-4 mt-2 text-center text-black">Life Coach</p>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/2 lg:mt-0 mt-16">
                <div class="bg-white border rounded-md border-gray-200 relative sm:p-10 p-6">
                    <div>
                        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M24.5767 14.331H18.6157C18.7176 9.55831 19.9498 9.01654 21.4974 8.86191L22.0942 8.78063V3.53254L21.4063 3.57304C19.3854 3.69795 17.1512 4.09818 15.6605 6.11977C14.3539 7.89195 13.7778 10.7872 13.7778 15.2317V23.4674H24.5767V14.331Z" fill="#4338CA" />
                                <path d="M10.7988 23.4674V14.331H4.91744C5.01934 9.55831 6.21168 9.01654 7.75927 8.86191L8.31634 8.78063V3.53254L7.66816 3.57304C5.64729 3.69795 3.39306 4.09818 1.90245 6.11977C0.595916 7.89195 -5.72205e-06 10.7872 -5.72205e-06 15.2317V23.4674H10.7988Z" fill="#4338CA" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="24.5767" height="27" fill="white" transform="translate(24.5767 27) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p class="text-base leading-6 text-gray-600 mt-4">Typically a standout even amongst the best of Châteauneuf-du-Pape, this monumental bottling is sourced from 75- to 90-year-old Grenache planted in a diversity of pebbly, sandy and clay soils. </p>
                    <div class="absolute bottom-0 -mb-4 ml-10">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" width="20" height="21" fill="white" />
                            <path d="M21.5 6L11.5 21L1 6" stroke="#E5E7EB" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div class="flex items-center mt-7">
                    <div class="w-12 h-12 border border-indigo-700 rounded-full flex items-center justify-center">
                        <img src="https://i.ibb.co/C6bwf12/Mask-Group.png" class="w-10 h-10 rounded-full" alt="profile" />
                    </div>
                    <div class="flex flex-col items-start ml-4">
                        <p class="text-base font-semibold leading-4 text-yellow-500">Henry Jack</p>
                        <p class="text-base leading-4 mt-2 text-center text-black">Business - Engineering</p>
                    </div>
                </div>
            </div>

        </div>
    </div>




<div class="bg-quote container flex items-center justify-center h-screen mx-auto">
  <blockquote class="bg-black font-serif  p-4 text-center text-white md:p-8">
    <p class="font-bold italic text-3xl">
      &ldquo;Wine flies when you're having fun.&rdquo;
    </p>
  </blockquote>
</div>



<div class="font-serif leading-normal mx-auto py-12 px-4 md:px-24 bg-white text-black">
  <p class="mb-4 text-lg">
 <span style="font-size: 2rem">We </span>  at Vasundhara Vineyards produce world class wines using our home grown grapes.We are associated with world’s renowned oenologist, who is producing world class and best quality wines for our valued wine connoisseurs.

</p> 

 <p class="mb-4 text-lg">
     
     <span style="font-size: 2rem">Winery </span>  is located at Nasik, the city is also known as the Wine Capital of India. The topography of Nasik is similar to France, which is very helpful for good wine production.Our location with its cold weather is best to bring out the wine taste. The weather also helps in the process of preservation.
     </p> 

     <p class="mb-4 text-lg">
<span style="font-size: 2rem"> It's  </span> a family's business with an experience and expertise for more than 3 generations. We have adapted the next level approach in the wine industry. We are very well connected with the wine enthusiasts in India with the message that the Indian wines can globally perform well as compared to international brands.

</p>
  
  </div>




</template> 

<style scoped>

/**
 * Custom CSS
 *
 * Add this to your Tailwind CSS file,
 * or to a separate, custom CSS file.
 *
 * Update the background images to suit
 * your project.
 */
.bg-header {
  background: url(https://vasundharavineyards.com/static/img/back/markus-winkler-HX7F9nL6qeE-unsplash.jpg) center center no-repeat;
  background-attachment: fixed;
  background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
background-size: cover;
}


.bg-quote {
  background: url(https://vasundharavineyards.com/static/img/back/juraj-filipeje-V_X0uBi_8eQ-unsplash.jpg) center center no-repeat;
  background-attachment: fixed;
}
.font-serif {
/* font-family: 'Georgia', sans-serif; */
font-family: 'Helvetica', 'Arial', sans-serif;
font-size: 16px;
                                                
}

</style>
