
<template> 
    <div class="modal-overlay" id="modal-overlay"></div>
     <div class="modal" id="modal">
     <div class="box"> 
      <h3>Vasundhara Vineyards</h3>
      <p>Please enter your birth Year below: {{ age_valid }} </p>
      
      <div v-if="!age_valid" class="warning"> You need to wait for a few years to visit site! </div> 
      <div class="inputyear"> 
      <input class="input" type="text" placeholder="YYYY" autofocus v-model="age" name="age">
      <div v-if="age"> <a class="btn btn-alpha"><button @click="verify_age"  id="refresh-page">OK</button> </a>   </div> 
      </div> 

       <p>You have to be 21+ year old to enter this site.</p>

  
      <p class="decor-line"><span>Or</span></p>
      
      <div class="inputyear"> 

      <a href="https://www.google.com" class="btn btn-beta" id="reset-session">EXIT</a>
     
      
      </div> 
      <div class="inputyear"> 

 <small>Always enjoy responsibily.</small>
 </div> 
      </div> 


 </div>



</template> 

<script> 

import { mapActions } from 'vuex'

export default {




   name: 'Agelimit',

    data() {
      return {
        age: null,
        age_valid: true,
      }
    },
       
    methods: {

        ...mapActions({
            agelimit: 'agelimit',
        }),
       verify_age() {
       
          if (2022 - parseInt(this.age)<= 21)
          {
              this.age_valid = false;
              return
           } 
           else {

            this.age_valid = true
            this.agelimit();
          }

          }
       }
   }
   

</script> 



<style scoped>

.modal {
  /* This way it could be display flex or grid or whatever also. */
  display: block;
  
  /* Probably need media queries here */
  width: 70%;
  max-width: 50%;
  height: 30%;
  max-height: 100%;
  position: fixed; 
  z-index: 100;
  left: 50%;
  top: 50%;
  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);
  
  /* If known, negative margins are probably better (less chance of blurry text). */
  /* margin: -200px 0 0 -200px; */
  
  background: white;
  border-radius: 40px;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
}


@media (max-width:500px) {
  .modal{
    width:100%;
    max-width: 100%;
  }
}


.closed {
  display: none;
}

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;

    background: rgba(0, 0, 0, 0.6);
  }
  
.modal .close-button {
  position: absolute;
  
  /* don't need to go crazy with z-index here, just sits over .modal-guts */
  z-index: 1;
  
  top: 10px;
  
  /* needs to look OK with or without scrollbar */
  right: 20px;
  
  border: 0;
  background:white;
  color: black;
  padding: 5px 10px;
  font-size: 1.3rem;

}

/*----------------------------------------------   
-Defualt to border-box
-----------------------------------------------  */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
 
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: 'Helvetica', 'Arial', sans-serif;
  line-height: 1.4;
}

/*----------------------------------------------   
--Fluid body sizing
-----------------------------------------------  */
body {
  font-size: 100%;
}
@media (min-width: 32em) {
  body {
    font-size: 110%;
  }
}
@media (min-width: 54em) {
  body {
    font-size: 111%;
  }
}
@media (min-width: 74em) {
  body {
    font-size: 115%;
  }
}
@media (min-width: 96em) {
  body {
    font-size: 135%;
  }
}

a.btn {
  background-color: #54A984;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  letter-spacing: 0.1em;
  padding:10px 30px 10px 30px;
  border-radius: 20px;
  margin: 10px 0px 10px 10px;

}

a.btn.btn-beta {
  background-color: #e36e3a;
  margin: auto;
  width: 30%;
  padding: 10px;
  text-align: center;
}

a.btn.btn-alpha {
  background-color: green;
}

.decor-line {
  position: relative;
  top: 0.7em;
  border-top: 1px solid #ccc;
  text-align: center;
  max-width: 40%;
  margin: 0.5em auto;
  display: block;
  padding: 0.1em 1em;
  color: #ccc;
}
.decor-line span {
  background: #fff;
  color: #ccc;
  position: relative;
  top: -0.7em;
  padding: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
}

.overlay-verify {
  background: #000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.box {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 9;
  width: 30%;
  height: 20%;
  display: table;
}

@media (max-width:500px) {
  .box{
    width:100%;
    max-width: 100%;
  }
}

.box .box-left, .box .box-right {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 5%;
}
@media (min-width: 54em) {
  .box .box-left, .box .box-right {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
  }
}
.box  {
  position: relative;
  z-index: 3;
}


@media (min-width: 992px) and (max-width: 1199px) {

      .box {
        background: white;
    }

}
.box  {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50px;
 
}
.box .box-right {
  text-align: center;
}
.box  h3 {
  color: #555;
  letter-spacing: 0.07em;
  border-bottom: 1px solid #eee;
  padding-bottom: 1em;
  margin-top:10px;
  font-size: 1.5rem;
  text-align: center;

}
.box  p {
  color: black;
  text-align: center;
}
.inputyear {
  margin:auto;
  width: 80%;
  max-width: 100%;
  color: black;
  display: flex;
  justify-content: center;
  

}

/* 
.inputyear .input {
  margin:auto;
  padding:10px;
  border:2px red;

}
*/


input[type=text] {
  margin:10px 0px 10px 0px;
  width: 30%;
  max-width: 100%;
  padding: 4px 20px;
  box-sizing: border-box;
  border:1px solid gray;
  border-radius: 20px;
  
}

.warning {
  color: red;
  margin: auto;
  text-align: center;
}


</style>
