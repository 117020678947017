<template>
  <body class="overflow-hidden dark:bg-gray-900">


        <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">


            <div class="flex justify-center items-center lg:flex-row flex-col px-0 md:px-10 gap-0 md:gap-4">
                <!-- Description Div -->
       
                <div class="w-full sm:w-96 md:w-8/12 lg:w-6/12 items-center px-10">
                   
                    <h2 class="font-semibold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white mt-4 text-center">Perfect Fruit Dry Red Wine</h2>
      
                    <div class="flex flex-row justify-between mt-5">
                       <img class="dark:hidden" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/productDetail4-svg1.svg" alt="stars">
                       <img class="hidden dark:block" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/productDetail4-svg1dark.svg" alt="stars">
                        <p class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 font-normal text-base leading-4 text-gray-700 hover:underline hover:text-gray-800 dark:text-white duration-100 cursor-pointer">22 reviews</p>
                    </div>
      
                    <p class="font-normal text-base leading-6 text-white  mt-7 text-center">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 mt-6 dark:text-white"> <!-- price --></p>
      
      
         <div class="lg:mt-11 mt-10">
                        <div class="flex flex-row justify-between">

                           <p class="mt-8">  Perfect Fruit Dry Red Wine </p> 
                          <table class="text-sm"> 
                              <tr> 
                                  <td> Taste : Dry  </td> 
                              </tr> 
                              <tr> 
                                  <td> Smell : Normal </td> 
                               </tr> 
                               <tr> 
                                   <td> Volume: 750 ml  </td> 
                                </tr> 
                                <tr> 
                                    <td> Alcohol: 13% v/v </td> 
                                </tr> 
                                </table> 
                                
</div> 
         </div>
      
                    <button class="rounded rounded-xl focus:outline-none focus:ring-2 hover:bg-black focus:ring-offset-2 focus:ring-gray-800 font-medium text-base leading-4 text-white bg-gray-800 w-full py-5 lg:mt-12 mt-6 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100">Contact Us!</button>
                </div>


                
      
                <!-- Preview Images Div For larger Screen-->
      
                <div class="w-full sm:w-96 md:w-8/12 lg:w-6/12 flex lg:flex-row flex-col lg:gap-8 sm:gap-6 gap-4 bg-transparent">
                    <div class="w-full lg:w-8/12 bg-transparent flex justify-center items-center">
                        <img src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine_dry.png" alt="Wooden Chair Previw" class="rounded rounded-xl"/>
                    </div>
                    <div class="w-full lg:w-4/12 grid lg:grid-cols-1 sm:grid-cols-4 grid-cols-2 gap-6">
                        <div class=" flex justify-center items-center py-4">
                            <img src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine_dry.png" alt="Wooden chair - preview 1" class="rounded rounded-xl" />
                        </div>
                        <div class="flex justify-center items-center py-4">
                            <img src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine_dry.png" alt="Wooden chair - preview 2" class="rounded rounded-xl" />
                        </div>
                        <div class="flex justify-center items-center py-4 hidden md:block">
                            <img src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine_dry.png" alt="Wooden chair- preview 3" class="rounded rounded-xl"/>
                        </div>
                    </div>
                </div>


            </div>






            <div class="flex justify-center items-center w-full px-0 md:px-10 my-4 md:my-10">

                <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        
                            <div class="ml-10 shadow-xl pb-10"> 
                                <table> 
                                    <tr> 
                                        <td rowspan="2" class="w-24 pt-10"> 
                                             <img class="dark:hidden"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg2.svg" alt="drink">
                                             <img class="hidden dark:block"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg2dark.svg" alt="drink">
                                        </td> 
                                        <td> 
                                              <p class="font-semibold text-xl leading-5 text-gray-800 dark:text-white lg:mt-10 mt-9">Wine Style  </p> 
                                        </td>
                                    </tr> 
                                     <tr> 
                                         <td> 
                                             <p class="text-normal text-base leading-6 text-white  mt-4">Original fruits (grape) natural flavor with dry red wine finish.</p>
                                         </td>
                                     </tr>
                                 </table>
                          </div>
                    
                </div>
                <div>
                        
                         <div class="ml-10 shadow-xl pb-10"> 
                            <table> 
                                <tr>
                                    <td rowspan="2" class="w-24 pt-10"> 
                                      <img class="dark:hidden"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg3.svg" alt="hardware">
                                       <img class="hidden dark:block"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg3dark.svg" alt="hardware">
                                   </td>
                                   <td> 
                                       <p class="font-semibold text-xl leading-5 text-gray-800 dark:text-white lg:mt-10 mt-9">Food Match</p>
                                   </td>
                                </tr>
                                <tr>
                                     <td> 
                                         <p class="text-normal text-base leading-6 text-white  mt-4">Enjoy with baked pasta, roasted meat or your Favorite dishes.</p>
                                    </td>
                              </tr>
                            </table>
                          </div>
                    
                  </div>

                    <div> 
                    
                        <div class="ml-10 shadow-xl pb-10"> 
                        <table> 
                            <tr> 
                                <td rowspan="2" class="w-24 pt-10"> 
                                   <img class="dark:hidden"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg5.svg" alt="Eco-friendly">
                                    <img class="hidden dark:block"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg5dark.svg" alt="Eco-friendly">
                            </td>
                            <td> 
                              <p class="font-semibold text-xl leading-5 text-gray-800 dark:text-white lg:mt-10 mt-9">Serving</p>
                            </td>
                            </tr>
                            <tr> 
                                <td >
                       <p class="text-normal text-base leading-6 text-white  mt-4">Best served at room temperature (12-15 °C )</p>
                           </td>
                           </tr>
                           </table>
                           
                           </div>

                    </div>
                    
                    <div> 
                    
                        <div class="ml-10 shadow-xl pb-10"> 
                            <table> 
                                <tr> 
                                    <td rowspan="2" class="w-24 pt-10">

                                
                        <img class="dark:hidden"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg6.svg" alt="drink">
                        <img class="hidden dark:block"  src="https://vasundharavineyards.com/static/img/handyimages/productDetail4-svg6dark.svg" alt="drink">
                       </td>
                       <td> 
                    <p class="font-semibold text-xl leading-5 text-gray-800 dark:text-white lg:mt-10 mt-9">Bottle Handling</p>
                    </td>
                    </tr>
                    <tr> 
                        <td>
                      <p class="text-normal text-base leading-6 text-white  mt-4">Very Attractive Design, Handy to carry!</p>
                      </td>
                      </tr>
                      </table>
                      </div>

                    </div>
                    
    
                </div>
            </div>




        </div>


    </body>
</template>

<script>
export default {
    data(){
        return{
            counter:1,
            rotateimg:false
        }
    },
    methods:{
          plus() {
                this.counter
                this.counter=this.counter + 1;
            },
      
             minus() {
                var preValue = document.getElementById("counter").value;
                if (parseInt(preValue) != 0) {
                    this.counter=this.counter - 1;
                }
            },
      
             rotate() {
                if(this.rotateimg==true){
                    this.rotateimg=false
                }else{
                    this.rotateimg=true
                }
            }
    }
}
</script>

<style>

</style>
