import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/Home.vue';
import Products from './components/Products.vue';
import About from './components/About.vue';
import Contact from './components/Contact.vue'
import DryredWine from './components/individual/DryredWine.vue'
import FruitredWine from './components/individual/FruitredWine.vue'
import DrywhiteWine from './components/individual/DrywhiteWine'
import FruitwhiteWine from './components/individual/FruitwhiteWine'
import Winemaker from  './components/Winemaker.vue'



const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/products', name: 'Products', component: Products },
    { path: '/about', name: 'About', component: About },
    { path: '/contact', name: 'Contact', component: Contact },
    { path: '/products/dryredwine', name: 'DryredWine', component: DryredWine},
    { path: '/products/fruitredwine', name: 'FruitredWine', component: FruitredWine},
    { path: '/products/drywhitewine', name: 'DrywhiteWine', component: DrywhiteWine},
    { path: '/products/fruitwhitewine', name: 'FruitwhiteWine', component: FruitwhiteWine},
    { path: '/winemaker', name: 'Winemaker', component: Winemaker}
  
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    //next('/m/:vendor_id');
    next();

  } else {
    next();
  }
});

export default router;

``
