import { createApp } from 'vue'
import store from '@/store/index.js';
import router from '@/router.js';
import App from './App.vue'
import BookService from './components/BookService.vue';
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    
    faPhone,
    faCheck,
    faTimes,
    faCogs,
    faEnvelope,
    faHome,
    faTags,
    faList,
    faShoppingCart,
    faChartLine,
    faImages,
    faFolderOpen,
    faFileExcel,
    faPrint,
    faUser,
    faBars,
    faSignInAlt,
    faSearch,
    faEdit,
    faSpinner,
    faTruck,
    faBolt,
    faStore,
    faCommentAlt,
    faLocationPin,
    faChevronDown,
    faWineBottle,
    faHistory,
    faBlog,

} from "@fortawesome/free-solid-svg-icons";
import { faApple, faInstagram, faFacebook, faTwitter, faWhatsapp, faGooglePlusG } from "@fortawesome/free-brands-svg-icons";
library.add(
    
    faPhone, 
    faCheck, 
    faTimes, 
    faCogs, 
    faEnvelope,
    faHome, 
    faTags, 
    faList,
    faApple, 
    faInstagram, 
    faFacebook, 
    faTwitter, 
    faShoppingCart, 
    faChartLine, 
    faImages, 
    faFolderOpen, 
    faFileExcel, 
    faPrint, 
    faUser, 
    faBars,
    faSignInAlt,
    faSearch,
    faEdit,
    faSpinner,
    faTruck,
    faBolt,
    faStore,
    faCommentAlt,
    faLocationPin,
    faWhatsapp,
    faGooglePlusG,
    faChevronDown,
    faWineBottle,
    faHistory,
    faBlog,
    
    );

    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


const app = createApp(App)
app.config.globalProperties.$backendserver = 'https://api.lognote.live';
 // app.config.globalProperties.$backendserver = 'http://localhost:9600';

app.config.globalProperties.$filters = {
    convertTime(value) {
        return moment(String(value)).format('DD MMMM, YYYY, h:mm:ss a')
    }
}


app.use(router);
app.use(store);
app.component('book-service', BookService);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount('#app')

