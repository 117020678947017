import { createStore } from 'vuex';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const bookservice = window.localStorage.getItem('bookservice');
const agelimit = window.localStorage.getItem('agelimit');

console.log("The agelimit is");
console.log(agelimit)
const state = {
    bookservice: bookservice ? bookservice: false,
    agelimit: agelimit ? agelimit: false,
}
const store = createStore({
    
    state,
    mutations,
    actions,
    getters
  });


  export default store;
  
