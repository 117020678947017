<template> 


<div class="overflow-y-hidden container"> 
    <div class="flex item-center mt-20 "> 
     <h2 class="w-full font-bold text-center  pl-0 md:pl-10 lg:text-4xl text-3xl lg:leading-10 leading-9">
MEET OUR WINEMAKER
</h2>
  </div> 
</div> 
   <div class="mx-24 h-4 mb-4 border-b-2 border-yellow-400 border-dashed"> </div> 




    <body class="overflow-y-hidden">
        <div class="xl:mx-auto xl:container">
            <div class="px-4">
                <div class="flex flex-col-reverse lg:flex-row">
                    <div class="w-full md:w-1/2 -mr-32">
                        <img src="https://vasundharavineyards.com/static/img/winemaker/winemaker_two.jpg" alt="Wine Maker" class="w-full md:w-1/2  h-auto object-cover object-right rounded-3xl" />
                    </div>
                    <div class="w-full md:w-1/2 mt-10">
                    
                        <p class="text-sm font-italic leading-none text-yellow-300 pb-2">WINEMAKER</p>
                        <p class="md:text-3xl lg:text-4xl text-2xl font-semibold lg:leading-9 text-white lg:pb-6 md:pb-4 pb-2">Dr. Brij Mohan Mishra</p>
                        <p class="mt-10 text-left text-white md:pb-10 pb-8">
                            
 <span style="font-size: 2rem;"> Brij Mohan </span>  Produced BigMaster Wine first in Kathmandu, Nepal Year 2000, where he quickly proved his outstanding winemaking talent, becoming one of the best winemakers in Nepal. 
As Chief Winemaker since 2000, Dr. Mishra is determined to craft innovative wines that continue to build the international recognition of Big Master Wine for their exceptional quality and distinctive style.
Dr. Mishra job isn’t a small one. He has responsibility for the style and quality of every bottle of BigMaster wine.</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </body>



    <body class="overflow-y-hidden">
        <div class="xl:mx-auto xl:container">
            <div class="lg:px-20 md:px-6 px-4 md:py-12 py-8">
                <div class="flex flex-col-reverse lg:flex-row">
                       <div class="w-full md:w-1/2">
                       <p class="mt-10 text-left text-white md:pb-10 pb-8 mr-0 md:mr-24">


<span style="font-size: 2rem;"> Brij Mohan ji </span>  says Wine making is all about time & patience it is a laborious process moreover, maintaining its taste, texture & flavor’s is more challenging an endeavor. We select the best varietal grapes for wine making, we have the most modern equipment’s and machines to process the wine, achieving the best quality of wine which produced a rich and flavorful taste to the consumer. we are always committed to provide the best quality wine to the consumer.   I believe in focusing on delivering the vision by focusing on repeatable, scalable, and of course profitable, growth opportunities. This involves leading and supporting the team to solve problems, identify options and make things happen. Vasundhara Vineyards Private Limited’s philosophy is unique, and my job is definitely one of a kind!

                       </p>
                       
                    </div>

                    <div class="hidden md:block w-1/2">
                        <img src="https://vasundharavineyards.com/static/img/winemaker/winemaker_one.jpg" alt="Wine Maker" class="w-full h-auto object-cover object-right rounded-3xl" />
                    </div>
                   
                </div>
            </div>
        </div>
    </body>

</template> 
