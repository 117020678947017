<template>
  <div class="section">
    <div class="w-full  py-28 mt-2 text-primary px-4 md:px-0">
      <div class="flex justify-between items-center flex-col">
        <div class="w-full px-0 md:px-10 lg:px-24">
          <div class="mb-10">
            <h3
              class="text-green-dark font-normal text-base text-yellow-200 mb-4"
            >
              We are
            </h3>
            <h1
              class="text-center text-4xl lg:text-6xl text-white mb-4 leading-120"
            >
              High Quality Wine Producers of India
            </h1>
            <p
              class="text-base md:text-xl font-light text-yellow-300 text-center tracking-normal"
            >
              We respect the wine culture!
            </p>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
            <!-- first product -->
            <div
              class="grid grid-cols-1 md:grid-cols-2 items-center border border-primaryBorder rounded-md py-4 bg-white"
            >
              <div class="flex justify-center mb-4 md:mb-0">
                <img
                  src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine_dry.png"
                  alt="Laptop Repair"
                  class="w-56 h-auto  rounded shadow-lg object-center"
                />
              </div>
              <div class="px-10">
                <h2 class="font-semibold tracking-normal text-black">
                  Perfect Fruit Dry Red Wine
                </h2>

                <p class="text-sm font-normal text-yellow-900 tracking-normal">
                  Original Fruits (Grape) natural flavor with dry red wine finish
                </p>
                <button
                  class="
                              text-white
                              bg-blue-500
                              py-2.5
                              px-12
                              shadow-md
                              rounded-full
                              inline
                              mt-8
                              font-bold
                              tracking-wider
                              uppercase
                              text-xs
                            "
                  id="btnLeft"
                >
                  <a :href="$router.resolve({ name: 'DryredWine' }).href">
                    Explore More
                  </a>
                </button>
              </div>
            </div>

            <!-- second product-->
            <div
              class="grid grid-cols-1 md:grid-cols-2 items-center border border-primaryBorder rounded-md py-4 bg-white"
            >
              <div class="flex justify-center mb-4 md:mb-0">
                <img
                  src="https://vasundharavineyards.com/static/img/landing/vasundhara_red_wine.png"
                  alt="Desktop Repair"
                  class="w-56 h-auto rounded shadow-lg"
                />
              </div>

              <div class="px-10">
                <h2 class="font-semibold tracking-normal text-black">
                  Perfect Fruit Sweet Red Wine
                </h2>
                <p class="text-sm font-normal text-yellow-900 tracking-normal">
                  Original Fruits (Grape) natural flavor with sweet red wine finish
                </p>
                <button
                  class="
                                text-white
                                bg-blue-500
                                py-2.5
                                px-12
                                shadow-md
                                rounded-full
                                inline
                                mt-8
                                font-bold
                                tracking-wider
                                uppercase
                                text-xs
                              "
                  id="btnLeft"
                >
                  <a :href="$router.resolve({ name: 'FruitredWine' }).href"> Explore More </a>
                </button>
              </div>
            </div>

            <!-- third product -->
            <div
              class="grid grid-cols-1 md:grid-cols-2  items-center border border-primaryBorder rounded-md  py-4 bg-white"
            >
              <div class="flex justify-center mb-4 md:mb-0">
                <img
                  src="https://vasundharavineyards.com/static/img/landing/vasundhara_white_wine_dry.png"
                  alt="Software Installation"
                  class="w-56 h-auto rounded shadow-lg"
                />
              </div>
              <div class="px-10">
                <h2 class="font-semibold tracking-normal text-black">
                  Perfect Fruit Dry White Wine
                </h2>
                <p class="text-sm font-normal text-yellow-900 tracking-normal">
                  Original fruits (grape) natural flavor with dry white wine
                  finish
                </p>
                <button
                  class="
              text-white
              bg-blue-500
              py-2.5
              px-12
              shadow-md
              rounded-full
              inline
              mt-8
              font-bold
              tracking-wider
              uppercase
              text-xs
            "
                  id="btnLeft"
                >
                <a :href="$router.resolve({ name: 'DrywhiteWine' }).href"> Explore More </a>
                </button>
              </div>
            </div>
            <!-- fourth product -->
            <div
              class="grid grid-cols-1 md:grid-cols-2 items-center border border-primaryBorder rounded-md  py-4 bg-white"
            >
              <div class="flex justify-center mb-4 md:mb-0 ">
                <img
                  src="https://vasundharavineyards.com/static/img/landing/vasundhara_white_wine.png"
                  alt="Hardware Sell"
                  class="w-56 h-auto rounded shadow-lg"
                />
              </div>
              <div class="px-10">
                <h2 class="font-semibold tracking-normal text-black">
                  Perfect Fruit Sweet White Wine
                </h2>
                <p class="text-sm font-normal text-yellow-900 tracking-normal">
                  Original fruits (grape) natural flavor with sweet white wine
                  finish
                </p>
                <button
                  class="
              text-white
              bg-blue-500
              py-2.5
              px-12
              shadow-md
              rounded-full
              inline
              mt-8
              font-bold
              tracking-wider
              uppercase
              text-xs
            "
                  id="btnLeft"
                >
                  <a :href="$router.resolve({ name: 'FruitwhiteWine' }).href">Explore More </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>
