<template>

    
   <body class="overflow-y-hidden">
        <div class="2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <p class="font-normal text-sm leading-3 text-indigo-700 hover:text-indigo-800 cursor-pointer pb-2 text-left">About Us</p>
            <div class="mt-10 flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
                <div class="w-full lg:w-6/12">
                    
                       <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M24.5767 14.331H18.6157C18.7176 9.55831 19.9498 9.01654 21.4974 8.86191L22.0942 8.78063V3.53254L21.4063 3.57304C19.3854 3.69795 17.1512 4.09818 15.6605 6.11977C14.3539 7.89195 13.7778 10.7872 13.7778 15.2317V23.4674H24.5767V14.331Z" fill="#4338CA" />
                                <path d="M10.7988 23.4674V14.331H4.91744C5.01934 9.55831 6.21168 9.01654 7.75927 8.86191L8.31634 8.78063V3.53254L7.66816 3.57304C5.64729 3.69795 3.39306 4.09818 1.90245 6.11977C0.595916 7.89195 -5.72205e-06 10.7872 -5.72205e-06 15.2317V23.4674H10.7988Z" fill="#4338CA" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="24.5767" height="27" fill="white" transform="translate(24.5767 27) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <h2 class="w-full font-extrabold pl-10 text-xl lg:text-4xl text-white lg:leading-10 leading-9 text-left">
                        WHAT DO YOU GET WHEN YOU POUR PASSION  
AND INNOVATION INTO A GLASS BOTTLE? 
                        </h2>

                          <h1 class="w-full mt-4  text-left border-b-2 border-yellow-400 border-dashed">

                       <span class="h1class uppercase" style="color:red">Big </span> <span class="h1classrest uppercase" style="color:black"> Master Wines  </span> 

                        </h1> 

                    <p class="font-normal text-base leading-6 text-white mt-24 text-left">
                      Big Master is all about pushing the limits. 
                      We come from Vasundhara Vineyards Pvt. Ltd, a place where excellence has been a habit over 
                      the last two decades. <b> Advanced DCBL Filtration </b> models to <b> International Standard Chilling </b>  to  <b> Micro 
                      Filtration. </b> 
                      
                      Our endeavour to keep unbottling our potential by employing <b> world-class technology</b>, has today, 
                      made us the <b> local wine producer serving the best quality of wines </b> . 
                      Every drop of Big Master wine, is a blend of cutting-edge technology and the pure joy of serving 
                      an exquisite taste devoid of any artificial additives. Making us a name to reckon with. 
                      </p> 
                      
                       <p class="font-normal text-base leading-6 text-white mt-4 text-left">
                    Our winemakers set the very highest of standards. From meticulous fruit selection, 
                    to fermentation in state-of-the-art, stainless steel vessels, small batch, experimental 
                    winemaking and maturation. our wines are crafted at every stage of production.
                    </p> 
                     <p class="font-normal text-base leading-6 text-white mt-4 text-left">
                         Winemaking technology are integrating modern technology to enhance wine making. Our Modern 
                         wine making technology paves the way for the most wonderful wines of the future. 
                         We use high-end technology to make better wine.
                        </p>
                </div>

                <div class="w-full hidden md:block mt-24 lg:w-6/12">
                    <img class="lg:block hidden w-full" src="https://vasundharavineyards.com/static/img/landing/vasundhara_all_transperent.png" alt="people discussing on board" />
                    <img class="lg:hidden sm:block hidden w-full" src="https://vasundharavineyards.com/static/img/landing/vasundhara_all_transperent.png" alt="people discussing on board" />
                    <img class="sm:hidden block w-full" src="https://vasundharavineyards.com/static/img/landing/vasundhara_all_transperent.png" alt="people discussing on board" />
                </div>
            </div>
<!-- 

            <div class="relative mt-24">    
                <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                    <div class="z-20 w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center">
                        <img src="https://magicmoppers.in/static/img/about/about-us-3-flag.svg" alt="flag" />
                    </div>

                  <img class="z-20" src="https://magicmoppers.in/static/img/about/about-us-3-note.svg" alt="note" />

                   <img class="z-20 sm:block hidden" src="https://magicmoppers.in/static/img/about/about-us-3-users.svg" alt="users" />
                </div>
                <hr class="z-10 absolute top-2/4 w-full bg-gray-200" />
            </div>

            --> 
           
    <!-- 
            <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                <div>
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6 text-left">Founded in 2012</p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 text-left">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div>
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6 text-left">50+ customers!</p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 text-left">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div class="sm:block hidden">
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6 text-left">Served 100,000 bottles!</p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6 text-left">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>
            <div class="sm:hidden block relative mt-8">
                <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                   <img src="https://magicmoppers.in/static/img/about/about-us-3-user.svg" alt="user" />
                </div>
                <hr class="z-10 absolute top-2/4 w-full bg-gray-200" />
            </div>
            <div class="sm:hidden grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
                <div>
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 mt-6 text-left">400k User</p>
                    <p class="font-normal text-base leading-6 text-gray-600 mt-6">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

--> 


<div class="w-70 h-24 border-b-2 border-dashed border-yellow-400">  </div> 
     
         
         
            <div class="flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 mt-16">
                <div class="w-full lg:w-6/12">
                    <h2 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-white text-left">Our Mission</h2>
                    <p class="font-normal text-base leading-6 text-white mt-6 w-full lg:w-10/12 xl:w-9/12 text-left">Provide most skilled work force to improve life of Senior generation, Help Works and overall community</p>
                    <p class="font-normal text-base leading-6 text-white w-full lg:w-10/12 xl:w-9/12 mt-10 text-left">Taking Challenge to help educate and improve skills of community helpers</p>
                </div>

<!-- 
                
                <div class="w-full lg:w-6/12">
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10">
                        
                        <div class="flex p-4 shadow-md">
                            <div class="mr-6">
                               <img class="mr-6"  src="https://magicmoppers.in/static/img/about/about-us-3-team.svg" alt="team card" />
                            </div>
                            <div class="">
                                <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 text-left">Team</p>
                                <p class="mt-2 font-normal text-base leading-6 text-gray-600 text-left"> 50 plus team of professionals. All are certified to handle the task. The average age of work force is 30 years.    </p>
                            </div>
                        </div>

                        
                        <div class="flex p-4 shadow-md">
                            <div class="mr-6">
                               <img src="https://magicmoppers.in/static/img/about/about-us-3-board.svg" alt="board card" />
                            </div>
                            <div class="">
                                <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 text-left">Board</p>
                                <p class="mt-2 font-normal text-base leading-6 text-gray-600 text-left">The team of 5 plus board members - most of them socially active leaders.</p>
                            </div>
                        </div>

                        
                        <div class="flex p-4 shadow-md">
                            <div class="mr-6">
                               <img src="https://magicmoppers.in/static/img/about/about-us-3-press.svg" alt="press card" />
                            </div>
                            <div class="">
                                <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 text-left">Approach</p>
                                <p class="mt-2 font-normal text-base leading-6 text-gray-600 text-left">We always think of Local First, We respect your culture! We try to accomodate your culture in your wine!</p>
                            </div>
                        </div>
                    </div>
                </div>
                --> 

            </div>
        </div>
    </body>
</template>

<script>
export default {

}
</script>

<style>

.aboutus {
 background: rgb(221,171,103);
background: linear-gradient(90deg, rgba(221,171,103,1) 0%, rgba(255,255,255,1) 19%, rgba(240,219,190,1) 44%, rgba(221,171,103,1) 65%, rgba(215,40,47,1) 84%);

}



.h1class {
    font-size: 4rem;
   -webkit-text-stroke: 2px #fff;
   text-shadow:
       2px 2px 0 #fff,
      -1px -1px 0 #fff,  
       1px -1px 0 #fff,
      -1px 1px 0 #fff,
       1px 1px 0 #fff;
}

.h1classrest {
    font-size: 3rem;
}

</style>