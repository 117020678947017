<template>
    
<book-service v-if="book_service">
    > </book-service>



<div   v-if="!book_service" id="body" class="">
    <div id="chat-circle" @click="bookservice" class="z-10 btn hide btn-raised">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="look h-6 w-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>

    </div>
</div>

<div v-if="!book_service" class="z-10 show text-lg text-gray-600 flex justify-center items-center chaticon">
    <span class="flex  items-center shadow-purple-500 text-purple-500 text-xl">Write to  Us<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 items-center flex " viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
            <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
        </svg></span>
</div>




<nav class="navbar flex items-right justify-between">
    <div > 
      <a :href="$router.resolve({ name: 'Home' }).href"> <img src="https://vasundharavineyards.com/static/img/logo/vasundharavineyards.png" class="h-24 w-auto rounded-lg mr-4">  </a> 
       </div> 
       <div class="hidden md:block"> 
       <a :href="$router.resolve({ name: 'Home' }).href" class="navbar-logo"> Vasundhara Vineyards</a>
       </div> 
       <div> 
     <ul class="hidden md:block navbar-links">
        <li class="">
          <a  :href="$router.resolve({ name: 'Home' }).href"> <font-awesome-icon :icon="['fas', 'home']" /> Home</a> 
        </li>
        <li class="">
          <a :href="$router.resolve({ name: 'Products' }).href"> <font-awesome-icon :icon="['fas', 'wine-bottle']" /> Products</a> 
        </li>
        <li class="navbar-dropdown">
          <a :href="$router.resolve({ name: 'About' }).href">    <font-awesome-icon :icon="['fas', 'history']" />  Our Story</a>
          <div class="dropdown">
             <a :href="$router.resolve({ name: 'About' }).href">About Us</a>
             <a :href="$router.resolve({ name: 'Winemaker' }).href" >Wine Maker</a>
         </div>
        </li>
        <li class="">
          <a href="#">   <font-awesome-icon :icon="['fas', 'blog']" /> Blog</a> 
        </li>
        <li class="">
          <a :href="$router.resolve({ name: 'Contact' }).href" >   <font-awesome-icon :icon="['fas', 'phone']" />  Contact Us</a>
        </li>
     </ul>
      </div> 

  <label class="block md:hidden cursor-pointer flex items-center px-3 py-2 rounded " style="color: #A2232E" for="menu-toggle" @click="toggleMenu"><svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg></label>
  <input class="hidden" type="checkbox" id="menu-toggle" />

  </nav>
  <div class="mobilemenu" v-show="activeMobile"> 
          <ul id="mobilemenu">
              <li class="mb-2 py-2 hover:text-gray-900"><a :href="$router.resolve({ name: 'Home' }).href"> <font-awesome-icon :icon="['fas', 'home']" /> &nbsp; Home</a> </li>
              <li class="mb-2 py-2"><a :href="$router.resolve({ name: 'Products' }).href"> <font-awesome-icon :icon="['fas', 'wine-bottle']" /> &nbsp;Products</a> </li>
              <li class="mb-2 py-2"> <a :href="$router.resolve({ name: 'About' }).href">    <font-awesome-icon :icon="['fas', 'history']" /> &nbsp; Our Story</a></li>
              <li class="mb-2 py-2">  <a href="#">   <font-awesome-icon :icon="['fas', 'blog']" />&nbsp; Blog</a> </li>
              <li class="mb-2 py-2" ><a :href="$router.resolve({ name: 'Contact' }).href" >   <font-awesome-icon :icon="['fas', 'phone']" /> &nbsp; Contact Us</a> </li>
            </ul>
   </div> 


</template> 

<script> 

import { mapGetters }  from 'vuex';
import { mapActions }  from 'vuex';


export default {

    Name: 'Navigation',
    data() {
      return {
        activeMobile: false,
      }
    },

     computed: {
              ...mapGetters({
            book_service: 'book_service',

        }),
     },
    methods: {

       ...mapActions({
            bookservice: 'bookservice',
        }),

        toggleMenu() {
          this.activeMobile = !this.activeMobile;
          console.log(this.activeMobile)
        }

    }



}


</script> 



<style scoped>

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: blue;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

.chaticon {

    position: fixed;
    bottom: 50px;
    right: 20px;

}

#chat-circle {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #5A5EB9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: white;
    padding: 28px;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn#my-btn {
    background: white;
    padding-top: 13px;
    padding-bottom: 12px;
    border-radius: 45px;
    padding-right: 40px;
    padding-left: 40px;
    color: #5865C3;
}

.btn#my-btn:hover {
    color: #eaebf0;
}

.show {
    position: fixed;
    bottom: 75px;
    right: 140px;
    width: 150px;
    height: 40px;
    background: #eaebf0;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 2px;
}

.hide {
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
}

.hide:hover {
    animation: none;
}

.chat:hover {
    transform: scale(1.1);
    transition: all ease-in 200ms;

}

#chat-circle:hover {
    transform: scale(1.1);
    transition: all ease-in 200ms;
    background-color: rgb(30, 202, 39);

}

.look {
    visibility: hidden;
}
@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}



@-moz-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-30px);
    }

    60% {
        -moz-transform: translateY(-15px);
    }
}

@-o-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -o-transform: translateY(0);
    }

    40% {
        -o-transform: translateY(-30px);
    }

    60% {
        -o-transform: translateY(-15px);
    }
}
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}



/* 


nav {
 animation-duration: 1s;
  animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
  animation-fill-mode: both;
}


nav {
  animation-name: slideDown;
}

.dropbtn {
  
  color: #A2232E;
  padding: 16px;
  border: none;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-left:24px;
  background-color: #A2232E;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 20px;
  z-index: 1;
}

.dropdown-content a {
  color:#A2232E;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color:  #d4af37;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color:  #A2232E;}

 */


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -1;
  opacity: 0;
  transition: 1s all;
}

.navbar {
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0px 70px;
  background-color: #fff;
}
.navbar-logo {
  color: #ff3f34;
  text-decoration: none;
  font-size: 2rem;
  padding: 0px 20px;
}

@media (min-width: 900px) and (max-width:1200px) {
  .navbar-logo{
    font-size: 1.2rem;
    white-space: normal;
  }
}


@media (min-width: 500px) and (max-width:899px) {
  .navbar-logo{
    font-size: .75rem;
    white-space: normal;
  }
}



.navbar-links {
  list-style-type: none;
}


@media (min-width: 700px) and (max-width:1700px) {
  .navbar-links{
    display: flex;
  }
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: #444;
  padding: 20px 20px;
  font-weight: 700;
  transition: 0.4s all;
}

.navbar-links li.navbar-dropdown {
  position: relative;
}

.navbar-links li.navbar-dropdown:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.navbar-links li.navbar-dropdown .dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 20px 0;
  top: 100%;
  transform: translateY(50px);
  left: 0;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 111;
  transition: 0.4s all;
}
.navbar-links li.navbar-dropdown .dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}
.navbar-dropdown .dropdown a:hover {
  padding-left: 30px;
}
.navbar-links li a:hover {
  color: #ff3f34;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  /* background-color: #f00; */
  background-size: cover;
}

.header-inner {
  text-align: center;
  color: #ff3f34;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, .8);
}

.header-inner h1 {
  /* font-family: "Great Vibes", cursive; */ 
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 130px;
}

.header-inner form input[type="search"] {
  position: relative;
  width: 500px;
  border: none;
  padding: 15px;
  border-radius: 27px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
  z-index: 11;
}
.header-inner form input[type="search"]:focus {
  outline: none;
}

.header-inner form input[type="search"]:focus + div {
  z-index: 1;
  opacity: 1;
}

::placeholder {
  color: #666;
  font-weight: 400;
}

.mobilemenu {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 20px 0px 10px 30px;
  
  z-index: 100;
  background: white;
  color: black;
  font-size: 1.2rem;
  
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  /* 
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  */
}



</style>
