<template>
    <div class="pt-4 footer">
        <div class="w-full border-white border-t lg:w-11/12 md:w-11/12 lg:mx-auto md:mx-auto">
            <div class="container mx-auto">
                <div class="xl:flex lg:flex md:flex pt-6">
                    <div class="w-11/12 xl:w-3/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0">
                        <div class="flex items-center mb-6 xl:mb-0 lg:mb-0">
                            <img src="https://vasundharavineyards.com/static/img/logo/vasundharavineyards.png" class="w-16 h-auto rounded-lg shadow-lg"> 
                            <p class="ml-3 font-bold text-xl">Vasundhara Vineyards  </p>
                        </div>
                    </div>
                    <div class="w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                        <ul>
                            <li class=" font-bold text-xl mb-6">Community</li>
                            <li class="text-base  hover:text-yellow-200 mb-5">
                                <a href="javascript:void(0)">About Us</a>
                            </li>
                            <li class="text-base hover:text-yellow-200 mb-5">
                                <a href="javascript:void(0)">Wine Production in India</a>
                            </li>
                        </ul>
                    </div>
                    <div class="w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                        <ul>
                            <li class=" font-bold text-xl mb-6">Getting Started</li>
                            
                            <li class="text-base hover:text-yellow-200 mb-5">
                                <a href="javascript:void(0)">Looking for Partnership</a>
                            </li>
                            <li class="text-base  hover:text-yellow-200 mb-5">
                                <a href="javascript:void(0)">Supply Grapes!</a>
                            </li>
                            
                        </ul>
                    </div>
                   
                </div>
                <div class="xl:flex flex-wrap justify-between xl:mt-24 mt-16 pb-6 pl-3 sm:pl-0">
                    <div class="w-11/12 xl:w-2/6 mx-auto lg:mx-0 xl:mx-0 mb-6 xl:mb-0">
                        <p class=" text-base">2022 Vasundhara Vineyards,  All Rights Reserved</p>
                    </div>
                    <div class="w-11/12 xl:w-2/6 mx-auto lg:mx-0 xl:mx-0 mb-6 lg:mb-0 xl:mb-0">
                        <ul class="xl:flex lg:flex md:flex sm:flex justify-between">
                            <li class=" hover:text-yellow-200 text-base mb-4 sm:mb-0">
                                <a href="javascript:void(0)">Terms of service</a>
                            </li>
                            <li class=" hover:text-yellow-200 text-base mb-4 sm:mb-0">
                                <a href="javascript:void(0)">Privacy Policy</a>
                            </li>
                            <li class=" hover:text-yellow-200 text-base mb-4 sm:mb-0">
                                <a href="javascript:void(0)">Security</a>
                            </li>
                            <li class=" hover:text-yellow-200 text-base mb-4 sm:mb-0">
                                <a href="javascript:void(0)">Sitemap</a>
                            </li>
                        </ul>
                    </div>
                    <div class="w-11/12 xl:w-1/6 lg:w-1/6 sm:w-11/12 mx-auto lg:mx-0 xl:mx-0 mb-6 lg:mb-0 xl:mb-0 mt-8 lg:mt-8 xl:mt-0">
                        <div class="flex justify-start sm:justify-start xl:justify-end space-x-6 pr-2 xl:pr-0 lg:pr-0 md:pr-0 sm:pr-0">
                            <div>
                               
                            </div>
                            <div>
                               
                            </div>
                            <div>
                                
                            </div>
                            <div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterWithSitemap",
};

</script>

<style scoped>
.footer {
    background: #2D2A26;
    color: white;

}
.back {
background-color: #e5e5f7;
opacity: 0.1;
background-image:  linear-gradient(30deg, #84180a 12%, transparent 12.5%, transparent 87%, #84180a 87.5%, #84180a), linear-gradient(150deg, #84180a 12%, transparent 12.5%, transparent 87%, #84180a 87.5%, #84180a), linear-gradient(30deg, #84180a 12%, transparent 12.5%, transparent 87%, #84180a 87.5%, #84180a), linear-gradient(150deg, #84180a 12%, transparent 12.5%, transparent 87%, #84180a 87.5%, #84180a), linear-gradient(60deg, #84180a77 25%, transparent 25.5%, transparent 75%, #84180a77 75%, #84180a77), linear-gradient(60deg, #84180a77 25%, transparent 25.5%, transparent 75%, #84180a77 75%, #84180a77);
background-size: 40px 70px;
background-position: 0 0, 0 0, 20px 35px, 20px 35px, 0 0, 20px 35px;

}
</style>