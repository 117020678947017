<template>
    
<div v-if="!accept" class="fixed pin-l pin-b p-6 bg-gray-200 sm:p-10 z-100 ">
    <div class="w-full sm:w-auto rounded-lg shadow-md  bg-gray-300 p-6">

        <div class="w-16 mx-auto relative -mt-10 mb-3">
            <img class="-mt-1" src="https://lottiefiles.com/images/svg/cookie.svg" alt="cookie">
        </div>

        <span class="w-full sm:w-48  block font-lf leading-normal text-grey-darkest text-xs mb-3">
            We care about your data, and we'd love to use cookies to make your experience better.
        </span>

        <div class="flex items-center justify-between">
            <a class="font-lf text-xs text-grey-dark mr-1" href="/page/privacy-policy">Privacy Policy</a>
            <button @click="accept_cookie" class="js-cookie-consent-agree bg-blue-800 trans text-xs hover:bg-lf-teal-dark rounded inline-block shadow px-8 py-2 text-white font-lf-bold cursor-pointer">
                Ok
            </button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'Cookies',
    data() {
        return {
            accept: true,
        }
    },

    methods: {
        accept_cookie () {
            this.accept = false;
            }
    },
}
</script>

<style scoped>
.pin-b {
    bottom:0;
}
.pin-l {
    left:0;
}

</style>}
