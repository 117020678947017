<template>
<div class="fixed z-10 mb-2" style="height: 400px; bottom: 90px; right: 10px; width: 350px;">
    <div id="center-text" class="bg-purple-800 p-5 animate__animated animate__rotateInDownRight  rounded-lg" v-if="box">
        <div class="grid grid-cols-10">
            <div class="col-span-9">
                <h2 class="text-gray-100 text-center text-2xl  font-extrabold border-b-4 mb-10">Write to Big Master</h2>
            </div>
            <div>
                <a @click="bookservice">
                    <font-awesome-icon :icon="['fas', 'chevron-down']" size="2x" style="color:white" /> </a>
            </div>

        </div>
        <form action="" class="">
            <div class="">
                <label class="block text-gray-200 text-sm font-bold mb-2" for="name">
                    Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your Name" v-model.trim="name.val" @blur="clearValidity('name')">
                <p v-if="!name.isValid" class="text-red-500 text-xs italic">Please fill out your name </p>
            </div>
            <div class="">
                <label class="block text-gray-100 text-sm font-bold mb-2" for="name">
                    Mobile Number
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline" id="mobile" type="text" placeholder="Your 10 digit mobile" v-model.trim="mobileNumber.val" @blur="clearValidity('mobileNumber')">
                <p v-if="!mobileNumber.isValid" class="text-red-500 text-xs italic">Please fill out mobile number with 10 digits </p>
            </div>
            

            <div class="">
                <label class="block text-gray-200 text-sm font-bold mb-2">
                    Message
                    <textarea class="shadow form-textarea mt-1 block w-full border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline" rows="5" placeholder="Your Device Issue Please..." v-model.trim="message.val" @blur="clearValidity('message')"></textarea>
                    <p v-if="!message.isValid" class="text-red-500 text-xs italic">Please enter your issue here </p>
                </label>
            </div>
            <div class="flex items-center justify-between">
                <button class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline" type="button" @click="write_service">
                    Submit
                </button>
            </div>
        </form>
    </div>

    <div id="body">
    </div>
</div>
</template>

<script>
import axios from "axios";

import {

    mapActions,
} from "vuex";

export default {
    name: 'BookService',
    data() {
        return {
            formIsValid: true,
            mobileNumber: {
                val: '',
                isValid: true,
            },

            message: {
                val: '',
                isValid: true,
            },
          
            name: {
                val: '',
                isValid: true,
            },
            box: true,
        }
    },

    methods: {

        ...mapActions({
            bookservice: 'bookservice',
        }),

        clearValidity(input) {
            this[input].isValid = true;
        },

        validateForm() {
            this.formIsValid = true;
            if (this.name.val === '') {
                this.name.isValid = false;
                this.formIsValid = false;
            }

            if (this.message.val === '') {
                this.message.isValid = false;
                this.formIsValid = false;
            }
            if (this.mobileNumber.value === '') {
                this.mobileNumber.isValid = false;
                this.formIsValid = false;
            }
            var mobileformat = /^(\d{10})$/;
            if (!this.mobileNumber.val.match(mobileformat)) {
                this.mobileNumber.isValid = false;
                this.formIsValid = false;
            }
           
        },

        write_service() {
            this.validateForm();
            if (!this.formIsValid) {
                return;
            }
            
            let data = {
                'mobile': this.mobileNumber.val,
                'message': this.message.val,
                'name': this.name.val,
               
            }
            axios
                .post(
                     '/api/contact',
                    JSON.stringify(data), {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                .then((response) => {
                    console.log(response)
                    this.box = !this.box;
                    this.bookservice()
                })
                .catch((error) => {

                    console.log(error)
                })

        },

    }

}
</script> 

<style scoped>
#center-text {}

#chat-circle {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: #5A5EB9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: white;
    padding: 28px;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn#my-btn {
    background: white;
    padding-top: 13px;
    padding-bottom: 12px;
    border-radius: 45px;
    padding-right: 40px;
    padding-left: 40px;
    color: #5865C3;
}
</style>
