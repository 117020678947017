export default {

    book_service(state) {
        state.bookservice = !state.bookservice
    },
    

    agelimit (state)  {
        state.agelimit= true;
        window.localStorage.setItem('agelimit', JSON.stringify(state.agelimit));
    },
}