<template>
  <div class="notification hidden md:block w-full bg-white h-10 py-2 border-b-2 border-yellow-100 shadow-xl">
    <div class="grid grid-cols-3">
      <div class="col-span-2 grid grid-cols-3 gap-2">
        <div style="font-size: 10px" class="text-left px-2 border-r-2 border-yellow-100 border-dashed">
        <font-awesome-icon :icon="['fas', 'location-pin']" /> 
         &nbsp; Pathardi Road, Opp Church,  Nashik, <br> 
         &nbsp;&nbsp; &nbsp; &nbsp;Maharashtra, Pin - 422 00999    
        </div>
        <div style="font-size: 10px" class="text-left  border-r-2 border-yellow-100 border-dashed">
         
          info@vasundharavineyard.com
        
          
        </div>
        <div style="font-size: 10px" class="text-left ">
          <p> We delivery accross Arunachal Pradesh, Orisaa </p> 
        </div>
      </div>
      <div class="grid grid-cols-10">
        <div class="col-span-7"></div>
        <div> <a href="https://www.facebook.com/vineyards.vasundhara">  <font-awesome-icon :icon="['fab', 'facebook']" />  </a> </div>
        <div><a href="https://www.instagram.com/vineyardsvasundhara/"> <font-awesome-icon :icon="['fab', 'instagram']" /> </a> </div>
        <div> <a href="https://api.whatsapp.com/send?phone=919425019960"> <font-awesome-icon :icon="['fab', 'whatsapp']" /> </a>  </div> 
      </div>
    </div>
  </div>
</template>

<style> 

.notification {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
  animation-fill-mode: both;
  color: #A2232E;
}


.notification {
  animation-name: slideDown;
}

</style>